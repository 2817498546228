const STORAGE_RESORT = 'ea-resort'
const STORAGE_FILE = 'ea-file'
const STORAGE_PROCESSED = 'ea-processed'

export const loadResortFromStorage = () => {
  return JSON.parse(window.localStorage.getItem(STORAGE_RESORT))
}

export const removeResortFromStorage = () => {
  window.localStorage.removeItem(STORAGE_RESORT)
}

export const saveResortToStorage = resort => {
  window.localStorage.setItem(STORAGE_RESORT, JSON.stringify(resort))
}

export const saveFileIdToStorage = fileId => {
  window.sessionStorage.setItem(STORAGE_FILE, JSON.stringify(fileId))
}

export const loadFileIdFromStorage = () => {
  return JSON.parse(window.sessionStorage.getItem(STORAGE_FILE))
}

export const removeFileIdFromStorage = () => {
  window.sessionStorage.removeItem(STORAGE_FILE)
}

export const saveProcessedToStorage = fileId => {
  window.sessionStorage.setItem(STORAGE_PROCESSED, JSON.stringify(fileId))
}

export const loadProcessedFromStorage = () => {
  return JSON.parse(window.sessionStorage.getItem(STORAGE_PROCESSED))
}

export const removeProcessedFromStorage = () => {
  window.sessionStorage.removeItem(STORAGE_PROCESSED)
}
