import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import Stepper from '@material-ui/core/Stepper'
import React from 'react'
import { Link } from 'react-router-dom'
import steps from '../services/steps'

const futureStep = ({ label, caption }) => (
  <>
    <div>{label}</div>
    <div className="step-link__caption">{caption}</div>
  </>
)

const previousStep = ({ path, clicked, ...step }) => (
  <Link className="step-link" to={path} onClick={clicked}>
    {futureStep(step)}
  </Link>
)

const Header = ({ step: current }) => {
  const resort = JSON.parse(window.localStorage.getItem('ea-resort'))
  return (
    <header className="header">
      <div className="header__title">
        <svg
          className="header__logo"
          viewBox="0 0 110 30"
          width="165"
          height="45"
        >
          <path d="M25 11.7c0 .3-.2.5-.4.5h-5c-.3 0-.5-.2-.5-.5v-.2c.7-1.2 2-2.1 3.7-2.1 1.7 0 2.5 1.1 2.3 2.4m-7.5 5h18.1a1 1 0 0 0 1-.7c3-7.9-2.9-12.7-12.3-12.7C15.7 3.4 7.2 8 4.6 15.3 1.9 22.9 8.5 26.4 17 26.4c6 0 13.6-2.2 17.5-7l.1-.3c0-.2-.2-.5-.5-.5H22.8a1 1 0 0 0-.8.4 4 4 0 0 1-3 1.4c-2.6 0-2.5-1.7-2-3.4.2-.3.4-.4.6-.4m17.6 8c-.2 0-.4-.2-.4-.4l.2-.4 5.6-5.1c.2-.2.5-.3.7-.3l.4.1h.1c2.5 1.2 5.6 2 9.3 2 0 0 4.1-.2 4.4-1.2.4-1-1.7-1-3-1.2l-3.2-.3c-6-.5-10.7-2.4-9.6-5.8 1.6-5.1 12-8 21.4-8 4.6 0 8.7.5 12.4 1.6.2.1.3.3.3.5v.4l-5 4.6s-.4.3-.7.3h-.4c-2.3-.8-4.9-1.5-8-1.5-1.4 0-3.5.3-3.8 1.2-.2.8 1.7 1 2.9 1.1l3.4.4c6.5.7 10.8 2.5 9.6 6.2-1.7 5.2-12 7.8-21.4 7.8-5.1 0-10.8-.7-15.2-1.8m54.6-6.6c-.2 0-.4.1-.4.3l-2 6.3a1 1 0 0 1-1 .7H72.7c-.3 0-.5-.3-.5-.5v-.2l6.1-19.5a1 1 0 0 1 1-.7h26.2c.3 0 .5.3.5.5v.2l-1.5 4.6a1 1 0 0 1-.9.7H92.1c-.2 0-.4.1-.4.3l-.4 1.2v.2c0 .3.2.5.5.5h9.7c.2 0 .4.2.4.5v.2l-1.3 4.2a1 1 0 0 1-1 .6h-9.9v-.1" />
        </svg>
        <div className="header__label">
          {resort && <b className="header__resort">{resort.name}&nbsp;</b>}
          <span className="header__label-sub"> Easy arrivals Club Med</span>
        </div>
      </div>
      <Stepper activeStep={current}>
        {steps.map((step, index) => (
          <Step key={step.path}>
            <StepLabel>
              {current > index ? previousStep(step) : futureStep(step)}
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </header>
  )
}

export default Header
