import primary from '@material-ui/core/colors/blue'
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles'
import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter, Route, Switch, useLocation } from 'react-router-dom'
import Header from './components/header'
import { classNameFromLocation, stepFromLocation } from './services/steps'
import './index.css'

const Schools = React.lazy(() => import('./routes/schools'))
const Parse = React.lazy(() => import('./routes/parse'))
const ParseReport = React.lazy(() => import('./routes/parse-report'))
const Run = React.lazy(() => import('./routes/run'))
const RunReport = React.lazy(() => import('./routes/run-report'))

const theme = createMuiTheme({ palette: { primary } })

const App = () => {
  const location = useLocation()
  const step = stepFromLocation(location)
  const mainClass = classNameFromLocation(location)
  return (
    <>
      <Header step={step} />
      <main className={mainClass}>
        <Suspense fallback="Chargement...">
          <Switch>
            <Route exact path="/">
              <Schools />
            </Route>
            <Route path="/parse">
              <Parse />
            </Route>
            <Route path="/parse-report">
              <ParseReport />
            </Route>
            <Route path="/run">
              <Run />
            </Route>
            <Route path="/run-report">
              <RunReport />
            </Route>
            <Route path="*">404</Route>
          </Switch>
        </Suspense>
      </main>
    </>
  )
}

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </ThemeProvider>,
  document.getElementById('root')
)
