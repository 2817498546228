import {
  removeFileIdFromStorage,
  removeResortFromStorage
} from '../services/storage'

const schoolsClicked = () => {
  removeResortFromStorage()
  removeFileIdFromStorage()
}

const steps = [
  {
    label: "Connexion à l'esf",
    path: '/',
    clicked: schoolsClicked
  },
  {
    label: 'Transfert du fichier',
    caption: 'au format CSV',
    path: '/parse'
  },
  {
    label: "Rapport d'analyse",
    caption: 'du fichier CSV',
    path: '/parse-report',
    className: 'parse-report'
  },
  {
    label: 'Inscriptions',
    caption: 'des élèves',
    path: '/run'
  },
  {
    label: "Rapport d'inscription",
    path: '/run-report',
    className: 'run-report'
  }
]

export const stepFromLocation = ({ pathname }) => {
  return steps.findIndex(step => step.path === pathname)
}

export const classNameFromLocation = ({ pathname }) => {
  const step = steps.find(step => step.path === pathname)
  return step ? step.className : undefined
}

export default steps
